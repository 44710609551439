import mixpanel from 'mixpanel-browser';

const MixpanelService = {
    init(token) {
        mixpanel.init(token, {
            debug: true,
        });
    },

    /**
     * Tracks an event with optional properties.
     * @param {string} eventName - Name of the event to track.
     * @param {Object} properties - Event properties (optional).
     */
    track(eventName, properties = {}) {
        mixpanel.track(eventName, properties);
    },

    /**
     * Sets user profile properties.
     * @param {string} userId - Unique identifier for the user.
     * @param {Object} properties - User profile properties.
     */
    identifyUser(userEmail, properties = {}) {
        mixpanel.identify(userEmail);
    },

    /**
     * Tracks a page view.
     * @param {string} pageName - Name or path of the page.
     */
    pageViewed(pageName) {
        this.track('page_view', { page: pageName });
    },

    /**
     * Tracks a popup view.
     * @param {string} popupName - Name or path of the page.
     */
    popupViewed(popupName) {
        this.track('popup_view', { pop_up: popupName });
    },

    /**
     * Tracks a popup view.
     * @param {string} buttonName - Name of icon clicked.
     * @param {string} pageName - Name or path of the page.
     */
    click(buttonName, pageName, additionalProperties = {}) {
        this.track('click', { button_name: buttonName, page: pageName, ...additionalProperties });
    },

    /**
     * Set user profile properties in Mixpanel.
     * @param {object} properties - Key-value pairs of user properties.
    */
    peopleSet(properties) {
        mixpanel.people.set(properties);
    },

    /**
     * Increment a numeric user property in Mixpanel.
     * @param {string} property - The property to increment.
     * @param {number} value - The amount to increment by.
    */
    peopleIncrement(property, value = 1) {
        mixpanel.people.increment(property, value);
    }
};

export default MixpanelService;
