import {setCookie, getCookie} from "@/helpers/functions";

export const getUTMTags= () => {
    return {
        utm_source: getCookie('utm_source') || null,
        utm_medium: getCookie('utm_medium') || null,
        utm_campaign: getCookie('utm_campaign') || null,
        utm_term: getCookie('utm_term') || null,
        utm_content: getCookie('utm_content') || null,
    };
}

export const setUTMTags = (key, value) => {
    setCookie(key, value, 24 * 60 * 60 * 1000)
}

export const setUTMTagsByRoute = (route) => {
    const utmParams = {
        utm_source: route.query.utm_source || null,
        utm_medium: route.query.utm_medium || null,
        utm_campaign: route.query.utm_campaign || null,
        utm_term: route.query.utm_term || null,
        utm_content: route.query.utm_content || null,
    };

    Object.entries(utmParams).forEach(([key, value]) => {
        if (value) setUTMTags(key, value);
    });
}
